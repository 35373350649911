import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  en: {
    translation: {
      "statictitle":"Amount to be charged",
      "cardName": "Card Name",
      "CardNumber": "Card Number",
      "ExpiryDate": "Expiry Date",
      "CCV": "CVV",
      "AccountName": "Account Name",
      "Zipcode": "Zipcode",
      "AccountNumber": "Account Number",
      "RoutingReference": "Routing Number",
      "AccountType": "Account Type",
      "pay": "Finalize and Pay",
      "VirtualAccountNumber": "Virtual Account Number",
      "toVirtualAccountNumber": "To Virtual Account NO",
      "Writeoffode" : "Write-off Code",
      "footerFirst" : "Click here if you agree to the Additional ACH Terms as presented here. You cannot continue without agreeing to the Additional ACH Terms.",
      "footerSecond" : "CONSUMER AUTHORIZATION FOR DIRECT PAYMENT VIA ACH (ACH DEBITS)",
      "footerThird" : "I authorize New U Life Corp (“New U Life”) to electronically debit the bank account attendant to my distributorship and/or customer account and to, if necessary, electronically credit the account’s erroneous debits. I understand that this authorization will remain in effect until the account holder or holders notify New U Life by phone, email or in writing that the account holders wish to revoke this authorization. The account holders acknowledge that New U Life requires at least 3 business days for such authorization. You will receive an email containing the agreement that needs to be completed and emailed or mailed to New U Life support.",
      "BankName": " Bank Name",
      "paymentType": 'Pay Through'
    }
    
  },
  ch: {
   
    translation: {
      "statictitle":"收费金额",
      "cardName": "卡名",
      "CardNumber": "卡号",
      "ExpiryDate": "到期日",
      "CCV": "CVV",
      "AccountName": "用户名",
      "Zipcode": "邮政编码",
      "AccountNumber": "帐号",
      "RoutingReference": "路由参考",
      "AccountType": "帐户类型",
      "pay": "继续支付",
      "VirtualAccountNumber":"虚拟帐号",
      "Writeoffode" : "注销代码",
      "BankName": "银行名",
      "toVirtualAccountNumber": "到虚拟帐号",
      "footerFirst" : "如果您同意此处提供的附加 ACH 条款，请单击此处。如果不同意附加 ACH 条款，您将无法继续。",
      "footerSecond" : "消费者授权通过 ACH 直接付款（ACH 借记）",
      "footerThird" : "我授权 New U Life Corp（“New U Life”）以电子方式借记我的经销商和/或客户账户的银行账户，并在必要时以电子方式记入账户的错误借记。我了解此授权将一直有效，直到账户持有人通过电话、电子邮件或书面形式通知 New U Life 账户持有人希望撤销此授权。账户持有人承认 New U Life 需要至少 3 个工作日才能获得此类授权。您将收到一封电子邮件，其中包含需要完成的协议并通过电子邮件发送或邮寄给 New U Life 支持",
      'paymentType': '支付通过'
    }
  },
  cht: {
   
    translation: {
      "statictitle":"收費金額",
      "cardName": "卡名",
      "CardNumber": "卡號",
      "ExpiryDate": "到期日",
      "CCV": "CVV",
      "AccountName": "用戶名",
      "Zipcode": "郵政編碼",
      "AccountNumber": "帳號",
      "RoutingReference": "路由參考",
      "AccountType": "帳戶類型",
      "pay": "繼續支付",
      "VirtualAccountNumber": "虛擬帳號",
      "Writeoffode" : "註銷代碼",
      "BankName": "銀行名",
      "toVirtualAccountNumber": "到虛擬帳號",
      "footerFirst" : "如果您同意此處提供的附加 ACH 條款，請單擊此處。如果不同意附加 ACH 條款，您將無法繼續。",
      "footerSecond" : "消費者授權通過 ACH 直接付款（ACH 借記",
      "footerThird" : "我授權 New U Life Corp（“New U Life”）以電子方式借記我的經銷商和/或客戶賬戶的銀行賬戶，並在必要時以電子方式記入賬戶的錯誤借記。我了解此授權將一直有效，直到賬戶持有人通過電話、電子郵件或書面形式通知 New U Life 賬戶持有人希望撤銷此授權。賬戶持有人承認 New U Life 需要至少 3 個工作日才能獲得此類授權。您將收到一封電子郵件，其中包含需要完成的協議並通過電子郵件發送或郵寄給 New U Life 支持。",
      'paymentType': '通過支付'
    }
  },
  
  es: {
   
    translation: {
      statictitle:
        "Kvanto pagenda"
    }
  },
  fr: {
    translation: {
      paragraph:
        "react-i18next est un puissant framework d'internationalisation pour React / React Native basé sur i18next."
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "sp",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
