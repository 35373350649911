import { CheckCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../../service/api';

const api = new Api();

const CitConSucess = () => {

    const location = useLocation();
    const [ details , setDetails ] = useState();

    useEffect(() => {
        updateTransaction();
        // eslint-disable-next-line
    },[])

    const updateTransaction = () => {
        const query = new URLSearchParams(location.search);
        const merchantRefrence = query.get('merchant_reference');
        const paymentInstanceToken = query.get('payment_instance_token')
        const body = {
            reference: merchantRefrence,
            paymentToken: paymentInstanceToken
        }
        let result = api.mainRestCallService('guest/citcon/redirect', "POST", body);
        if (result?.status === 1) {
            setDetails(result.data)
        } else {
            setDetails({})
        }
    }

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {
            details ?
                <div style={{ textAlign: 'center' }}>
                     <CheckCircleOutlined style={{color: '#52c41a', fontSize: '70px' , marginBottom: 10}}/>
                    <h2>Transaction Completed</h2>
                    <h4>Transaction Amount : <b>{details?.amount ?? 'N/A'}</b></h4>
                    <h4>Transaction Reference : <b>{details?.reference ?? 'N/A'}</b></h4>
                    <h3>Please try again after sometime.</h3>
                </div>
                :
                <Spin />
        }
        </div>
    )
}

export default CitConSucess;