import Api from "../../service/api";
import { PlaidLink } from 'react-plaid-link';
import { useContext , useState} from "react";
import { useTranslation } from "react-i18next";
import AccountContext from "../../config/AccountContext";
import { Form, Input, Select , Col , Button, message , Modal, Checkbox} from "antd";
import { CheckCircleOutlined } from '@ant-design/icons'
import { useEffect } from "react";

var api = new Api();
const { Option } = Select;

const AccountAdd = () => {
    
    const { t } = useTranslation();
    const [ form]  = Form.useForm();
    const [ loading , setLoading ] = useState();
    const [ plaidDetails , setPlaidDetails] = useState();
    const [ accountDeatils , setAccountDeatils ] = useState();
    const [ showStatusModal, setShowStatusModal] = useState(false);
    const { buttonLoading , orderDetails , accountPayment } = useContext(AccountContext);

    const getBankName = async (routingNumber) => {
        let result = await api.mainRestCallService('guest/bank-routing-details/' + routingNumber , 'GET');
        if (result?.status === 1) {
            form.setFieldsValue({ "bankName": result.data.bankName });
        } else {
            message.error('Invalid Routing Number')
            form.setFieldsValue({ "bankName": null });
        }
    }

    useEffect(() => {
        console.log(orderDetails)
    },[])
    
    const onSubmit = async(e) => {
        if(orderDetails.paymentChannel === 5){
            accountPayment(e)
            return;
        }
        setLoading(true)
        delete e.bankName;
        const body = {
            "instrumentType": 1,
            "bankDetail": e
        }
        let result = await api.mainRestCallService('guest/payment-instrument', 'POST', body);
        if(result?.data && result?.status === 1){
            // accountPayment(e)
            setAccountDeatils(result.data);
            if(result.data.status === 1){
                accountPayment(result.data)
            } else {
                getPlaidDetails();
            }
        } else {
            setLoading(false)
            message.error('Sorry !! Account details could not be updated.');
        }
    }

    const getPlaidDetails = async() => {
        let url = "guest/plaid/details";
        let result = await api.mainRestCallService(url , 'GET');
        if(result?.status === 1){
            setPlaidDetails(result.data)
            setShowStatusModal(true)
        } else {
            message.error('Please add valid account details');
            setLoading(false)
        }
    }

    const handleOnSuccess = async(token) => {
        setShowStatusModal(false)
        let url = "guest/plaid/link";
        let body = {
            "publicToken": token,
	        "consumerBankDetailsId": accountDeatils.paymentInstrumentId
        }
        let result = await api.mainRestCallService(url , 'POST' , body);
        setLoading(false)
        if(result?.status === 1){
            if(result?.data === true){
                accountPayment(accountDeatils)
            } else {
                message.error('Please add valid account details');
            }
        } else {
            message.error('Please add valid account details');
        }
    }

    const handleOnExit = () => {
        setShowStatusModal(false)
        setLoading(false)
        message.error('Please add valid account details');
    }



    return (
        <div style={{ margin: 'auto' }}>
            <Form labelCol={{ span: 7 }} wrapperCol={{ span: 10 }} style={{ width: '100%' }} form={form} onFinish={onSubmit}>

                {
                    orderDetails.paymentChannel === 2 ? 
                    <>
                        <Form.Item name="accountName" label={t('AccountName')} colon={false}
                            rules={[
                                { required: true, message: 'Please enter the name.' },
                                { pattern: new RegExp("^[a-zA-Z !@#$,./?%^^&*()]+$"), message: 'Please enter valid account name' },
                                { min: 3, message: 'Name cannot be less than 3 characters.' }
                            ]}>
                            <Input key="ach-accountName" placeholder="Enter Account Name" minLength={3} />
                        </Form.Item>

                        <Form.Item name="accountReference" label={t('AccountNumber')} colon={false}
                            rules={[
                                { required: true, message: 'Please enter the Account Number.' },
                                { pattern: new RegExp("^[0-9]+$"), message: "Please enter a valid Account name"},
                                { max: 20, message: 'Account number cannot be more that 20 digits.' },
                                { min: 3, message: 'Account number cannot be less than 4 digits.' }
                            ]}>
                            <Input key="ach-accountnumber" placeholder="Enter Account Number" minLength={3} maxLength={20}/>
                        </Form.Item>
                        
                        <Form.Item name="routingReference" label="Routing Number" colon={false}
                            rules={[
                                { required: true, message: 'Please enter the Routing Number.' },
                                { pattern: new RegExp("^[0-9]+$"), message: "Please enter a valid Routing Number" },
                                { min: 9, message: 'Routing number cannot be less that 9 digits.' }
                            ]}>
                            <Input key="add-routingnumber" placeholder="Routing Number" maxLength={9} onBlur={(e) => getBankName(e.target.value)} />
                        </Form.Item>

                        <Form.Item name="bankName" label="Bank Name" colon={false} rules={[{ required: true, message: 'Please enter a valid Routing Number.' }]}>
                            <Input key="add-bankname" placeholder="Bank Name" disabled />
                        </Form.Item>

                        <Form.Item name="accountType" label="Account Type" colon={false} rules={[{ required: true, message: 'Please select an Account Type.' }]}>
                            <Select key="add-account-type" placeholder="Select Account Type">
                                <Option value="checking">CHECKING</Option>
                                <Option value="saving">SAVING</Option>
                            </Select>
                        </Form.Item>
                    </> 
                    :
                    <>
                        <Form.Item name="accountReference" label={t('VirtualAccountNumber')} colon={false}
                            rules={[
                                { required: true, message: 'Please enter the Account Number.' },
                                { pattern: new RegExp("^[0-9]+$"), message: "Please enter a valid Account name"},
                                { max: 5, message: 'Account number cannot be more that 5 digits.' },
                                { min: 2, message: 'Account number cannot be less than 2 digits.' }
                            ]}>
                            <Input key="ach-accountnumber" placeholder="Enter Virtual Account Number" minLength={2} maxLength={5}/>
                        </Form.Item> 
                    </>
                }
                
                <Form.Item style={{marginBottom: 0}} name="remember" valuePropName="checked" wrapperCol={{ span: 24 }} rules={[{required: true , message: 'Please agree terms'}]}>
                    <Checkbox></Checkbox>
                </Form.Item>
                <p style={{ marginBottom: 0 }}>
                    <b>{t('footerFirst')}</b>
                    <br></br>
                    <span style={{ fontWeight: 200, fontFamily: 'calibri', color: '#4D4F53', marginBottom: 10, lineHeight: 2.4 }}>{t('footerSecond')}</span>
                    <br></br>
                    <span style={{ fontWeight: 200, fontFamily: 'calibri', color: '#4D4F53', marginBottom: 10 }}>{t('footerThird')}</span>
                </p>

                <Col style={{textAlign: 'center'}}>
                    <Button className="enable-btn" type="primary" htmlType="submit"  loading={loading || buttonLoading}>{t("pay")}</Button>
                </Col>

            </Form>

            <Modal visible={showStatusModal} closable={false} centered style={{ maxWidth: '424px' }} footer={null}>
                <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
                    <CheckCircleOutlined style={{ color: 'var(--positive)', fontSize: 24, paddingBottom: '20px' }} />
                    <span style={{ fontSize: 18 }}>Account added Successfully!</span>
                    <span style={{ fontSize: 16 , marginTop: 10 }}>Please continue for account verification</span>
                    <div style={{ textAlign: 'center', marginTop: 24 }}>
                        <PlaidLink className="plaid-button" onClick={() => setShowStatusModal(false)} clientName="dinky_labs,_llc" env={plaidDetails?.plaidEnvironment} product={["auth", "transactions"]} publicKey={plaidDetails?.publicKey} onExit={handleOnExit} onSuccess={handleOnSuccess}>Continue</PlaidLink>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AccountAdd;