import { useEffect, useState } from "react"
import { Form , Col , Button , message, Row } from "antd";
import CardContext from "../../../config/CardContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../service/api";

const api = new Api();

const InternalCardService = ({connectorId}) => {

    const { t } = useTranslation();
    const [ form ] = Form.useForm();
    const [ CVV , setCVV ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ expiry , setExpiry ] = useState(false);
    const [ cardName , setCardName ] = useState(false);
    const [ cardNumber , setCardNumber ] = useState(false);
    const { cardPayment , buttonLoading} = useContext(CardContext);

    useEffect(() => {
        loadJs();
         // eslint-disable-next-line
    },[]);

    const loadJs = () => {
        if (window?.renderJs) {
            const accessToken = document.getElementById('internalScript').getAttribute('data-token');
            window.renderJs({
                customerDetails : null,
                token: accessToken,
                onSuccess: (details) => {
                   addCard(true , details)
                },
                onFailure: (failureMessage) => {
                    addCard(false , failureMessage)
                },
                validateFields: (isValid, message , field ) => {
                    validateFields(isValid, message , field );
                },
                fields:{
                    ccnumber: {
                        placeholder: "**** **** **** ****",
                        selector: 'cnumber',
                    },
                    ccexp: {
                        placeholder: 'mm / yy',
                        selector: 'cexp'
                    },
                    cvv: {
                        placeholder: 'cvv',
                        selector: 'ccvv'
                    } ,
                    cname: {
                        placeholder: 'Name on card',
                        selector: 'cname'
                    } 
                }
            })
        } else {
            setTimeout(() => {loadJs()},10)
        }
    }

    const addCard = async(value , resposne) => {
        setLoading(false);
        if (value === true) {
            setLoading(true);
            let url = 'guest/payment-instrument/card';
            const body = {
                "cardToken": resposne.cardToken,
                "cardNumberLast4": resposne.cardNumberLast4,
                "expiryMonth": resposne.expiryMonth,
                "expiryYear": resposne.expiryYear,
                "cardType": resposne.cardType
            }
            var result = await api.mainRestCallService(url, 'POST', body);
            if (result?.status === 1) {
                resposne['paymentInstrumentId'] = result.data;
                resposne['connectorId'] = connectorId;
                cardPayment(resposne);
                message.success('Card added successfully')
            } else {
                message.error('Failed to add card')
            }
            setLoading(false)
        } else {
            message.error(resposne)
        }
    }  

    const validateFields = (isValid, message , field ) => {
        if (!isValid && field === 'ccnumber_field') {
            setCardNumber(true);
        } else if (isValid && field === 'ccnumber_field'){
            setCardNumber(false);
        } 

        if (!isValid && field === 'ccexp_field') {
            setExpiry(true)
        } else if (isValid && field === 'ccexp_field'){
            setExpiry(false)
        } 

        if (!isValid && field === 'cname_field') {
            setCardName(true)
        } else if (isValid && field === 'cname_field'){
            setCardName(false)
        } 

        if (!isValid && field === 'cvv_field') {
            setCVV(true)
        } else if (isValid && field === 'cvv_field'){
            setCVV(false)
        } 
    }

    const onSubmit = () => {
        if(cardNumber || expiry || CVV || cardName){
            return
        }
        setLoading(true)
        window.createCard();
    }


    return (
        <Form layout={'horizontal'} style={{ width: '100%' }} labelCol={{ span: 7 }} wrapperCol={{ span: 10 }} initialValues={{ country: 1 }} form={form} onFinish={onSubmit}>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('cardName')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="cname"></div>
                    {cardName ? <p className="input-label-error">Please enter valid Card Name</p> : null}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CardNumber')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="cnumber"></div>
                    {cardNumber ? <p className="input-label-error">Please enter valid Card Number</p> : null}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CCV')} </label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="ccvv"></div>
                    {CVV ? <p className="input-label-error">Please enter valid CVV Code</p> : null}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('ExpiryDate')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="cexp"></div>
                    {expiry ? <p className="input-label-error">Please enter valid Expiration Date</p> : null}
                </Col>
            </Row>

            <Col style={{textAlign: 'center'}}>
                <Button className="enable-btn" type="primary" htmlType="submit"  loading={loading || buttonLoading}>{t("pay")}</Button>
            </Col>

        </Form>
    )
}

export default InternalCardService;