import React from "react";
import { HashRouter, Route, Routes } from 'react-router-dom';
import Payment from "./pages/payment/Payment";
import ErrorPage from './pages/404'
import Success from './pages/payment/Components/Success'
import CitConFailure from "./pages/payment/CitConFailure";
import CitConSucess from "./pages/payment/CitConSucess";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/redirect/:order_token" element={<Payment />} />
        <Route path="/final" element={<Success />} />
        <Route path="/citcon/failure/:token" element={<CitConFailure />} />
        <Route path="/citcon/success/:token" element={<CitConSucess />} />
        <Route path="/" element={<ErrorPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
