import CardAdd from "./Cardadd";
import './Components/Layout.css';
import Api from '../../service/api';
import AccountAdd from "./AccountAdd";
import Layout from "./Components/Layout";
import { useTranslation } from "react-i18next";
import CardContext from "../../config/CardContext";
import React, { useEffect, useState } from "react";
import AccountContext from "../../config/AccountContext";
import { useParams, useNavigate } from 'react-router-dom';
import { Form, message, Select, notification, Button, Modal } from 'antd'

const api = new Api();
const { Option } = Select;
const getPaymentChannel = (paymentMode) => {
  switch (paymentMode) {
    case 1:
      return 2;
    case 2:
      return 5;
    case 3:
      return 3;
    case 4:
      return 8;
    case 5:
      return 9;
    case 6:
      return 10;
    case 7:
      return 11;
  }
}

const Payment = (props) => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { order_token } = useParams();
  const [connector, setConnector] = useState();
  const [redirectUrl, setRedirectUrl] = useState();
  const [orderDetails, setOrderDetails] = useState();
  const [showModal, setShowModal] = useState(false);
  const [layourError, setLayoutError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState();
  const [connectorTypes, setConnectorTypes] = useState();
  const [channelTypes, setChannelTypes] = useState();
  const [paymentChannel, setPaymentChannel] = useState();
  const [toVirtualAcccount, setToVirtualAcccount] = useState();
  const [enablePaymentSelection, setEnablePaymentSelection] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    getInitialToken();
    // eslint-disable-next-line
  }, [])
  
  const getInitialToken = async () => {
    const result = await api.mainRestCallService(`redirect/v1/${order_token}`, 'GET')
    if (result?.data) {
      if (result.data.currency !== 'USD') {
        setToVirtualAcccount(result.data.toVirtualCode)
      }
      setOrderDetails(result.data);
      sessionStorage.setItem('access_token', order_token);
      getConnectors(result.data.paymentMode, result.data.currency);
      if (!result.data.paymentMode) {
        setEnablePaymentSelection(true)
      }
    } else if (!result?.data || result.status === 0) {
      setLayoutError(true)
    }
  }

  const getConnectors = async (paymentMode , currency) => {
    const channel = paymentMode ? getPaymentChannel(paymentMode) : null;
    setPaymentChannel(channel)
    const result = await api.mainRestCallService('guest/connector?channelId='+(channel ? channel : ''), 'GET');
    if (result?.data) {
      const response = result.data.response.filter(element => element.channel.id !== 4);
      setChannelTypes(response);
      if(paymentMode){
        PaymentMethod(result.data.response[0].channel)
      }
    } else if (!result?.data && result?.error?.code !== 401) {
      if (result?.data?.length <= 0) {
        message.error('Failed to get Payment types')
      }
    }
  }

  const PaymentMethod = (channel) => {
    setPaymentChannel(channel.id)
    setConnectorTypes();
    setOrderDetails(prevState => ({ ...prevState, ['paymentChannel']: channel.id}));
    if(channel.connectors.length === 1 || channel.id === 3){
      setConnector(channel.connectors[0].id)
      const connector_id = channel.connectors.find(element => element.id === 3);
      if (connector_id) {
        setConnector(connector_id.id)
      }
      setOrderDetails(prevState => ({ ...prevState, ['connectorName']: channel.displayName}));
    } else {
      setConnectorTypes(channel.connectors);
    }
  }

  const qrPayment = (details) => {
    const body = {
      "paymentChannelId": paymentChannel,
      "connectorId": connector
    }
    doPayment(body);
  }

  const cardPayment = (details) => {
    const body = {
      "description": '',
      "paymentChannelId": paymentChannel,
      "paymentInstrumentId": Number(details.paymentInstrumentId),
      "connectorId": connector
    }
    doPayment(body);
  }

  const accountPayment = (details) => {
    const body = {
      "description": '',
      "paymentChannelId": paymentChannel,
      "connectorId": connector
    };
    if (orderDetails.paymentMode === 2) {
      body['virtualCode'] = details.accountReference
    } else {
      body["paymentInstrumentId"] = Number(details.paymentInstrumentId)
    }
    doPayment(body);
  }

  const doPayment = async (details) => {
    setButtonLoading(true)
    const result = await api.mainRestCallService('guest/checkout', 'POST', details);
    setButtonLoading(false)
    if (result?.data && result?.status === 1) {
      if (paymentChannel >= 8 && paymentChannel <= 10) {
        setShowModal(true);
        setRedirectUrl(result.data.redirectUrl)
        return
      }
      if (paymentChannel === 11){
          window.location = result.data.redirectUrl;
          return
      }
      const state = result.data
      state['amount'] = orderDetails.totalAmount;
      state['currency'] = orderDetails.currency;
      navigate('/final', { state });
    } else {
      notification.error({ message: 'Unable to create payment' });
    }
  }

  const selectedConnectors = (connectorId) => {
    setConnector(connectorId)
  }

  return (
    <>
      <Layout>
        {layourError && (
          <h1 style={{ textAlign: 'center' }}><b>Session Expired! </b> </h1>
        )}

        {(connectorTypes?.length === 0 && orderDetails?.paymentMode) && (
          <h1 style={{ textAlign: 'center' }}><b>Selected payment mode not available</b> </h1>
        )}

        {
          (orderDetails)&&
          <>
            {
              (enablePaymentSelection && channelTypes) &&
                <div className="paymentSelection" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 40 , flexFlow: 'wrap' }}>
                  {
                    channelTypes.map(({channel} , index) => 
                      <Button key={index} className={"btn btn-primary " + ((orderDetails?.paymentChannel === channel.id) && "selectedButton ") +  ((channelTypes.length > 4) && " marginBotton16")} 
                        onClick={() => PaymentMethod(channel)} htmlType={'button'}>{channel.displayName}
                      </Button>
                    )}
                </div>
            }


            {(orderDetails) ?
              <div className="">
                <Form layout={'horizontal'} wrapperCol={{ span: 10 }} labelCol={{ span: 7 }}>
                  <Form.Item label={t("statictitle")} colon={false}>
                    <span className="ant-form-text"><b>{orderDetails?.currency} {orderDetails?.totalAmount} </b></span>
                  </Form.Item>
                </Form>
                {
                  (paymentChannel === 5) &&
                  <Form layout={'horizontal'} wrapperCol={{ span: 10 }} labelCol={{ span: 7 }}>
                    <Form.Item label={t("toVirtualAccountNumber")} colon={false}>
                      <span className="ant-form-text"><b>{toVirtualAcccount}</b></span>
                    </Form.Item>
                  </Form>
                }
              </div>
              : null
            }

            {
              (connectorTypes && connectorTypes.length > 1) &&
              <Form layout={'horizontal'} labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
                <Form.Item name="cardChannel" colon={false} label={t('paymentType')} rules={[{ required: true }]}>
                  <Select placeholder="Select" onChange={value => { selectedConnectors(value) }}>
                    {
                      connectorTypes?.map(({ displayName, id }, index) => (
                        <Option key={id} value={id}>{displayName}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Form>
            }

            {
              ((!connectorTypes || connectorTypes?.length === 0) && connector) &&
              <Form layout={'horizontal'} wrapperCol={{ span: 10 }} labelCol={{ span: 7 }}>
                <Form.Item label={t("paymentType")} colon={false}>
                  <span className="ant-form-text"><b>{orderDetails.connectorName}</b></span>
                </Form.Item>
              </Form>
            }

            {
              (orderDetails?.paymentChannel >= 8 && orderDetails?.paymentChannel <= 11) &&
              <Form onFinish={qrPayment} layout={'horizontal'} wrapperCol={{ span: 10 }} labelCol={{ span: 7 }}>
                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
                  <Button className="enable-btn" type="primary" htmlType="submit" loading={buttonLoading}>{t("pay")}</Button>
                </Form.Item>
              </Form>
            }

            {
              (paymentChannel === 2 || paymentChannel === 5) &&
              <AccountContext.Provider value={{ accountPayment, orderDetails, buttonLoading }}>
                <AccountAdd />
              </AccountContext.Provider>
            }

            {
              (paymentChannel === 3 && connector) &&
              <CardContext.Provider value={{ connector, cardPayment, buttonLoading }}>
                <div className="CreditCardDetails">
                  <CardAdd />
                </div>
              </CardContext.Provider>
            }
          </>
        }


        <Modal visible={showModal} footer={false} closable={false} className={((orderDetails?.paymentMode === 5) ? "largeFrameModal" : "frameModal")}>
          <iframe width="100%" title="myFrame" height={(orderDetails?.paymentMode === 5) ? '600px' : '550px'} src={redirectUrl}></iframe>
        </Modal>


      </Layout>
    </>
  )
}
export default Payment;