import { Form , Col , Button , message, Row } from "antd";
import { useState , useContext , useEffect } from "react";
import { useLayoutEffect } from "react";
import CardContext from "../../../config/CardContext";
import Api from "../../../service/api";
import { useTranslation } from "react-i18next";

var api = new Api();

const NmiConnnector = (props) => {

    const { t } = useTranslation();
    const [ form ] = Form.useForm();
    const [ CVV , setCVV ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ expiry , setExpiry ] = useState(false);
    const { connector , cardPayment , buttonLoading } = useContext(CardContext)
    const [ cardNumber , setCardNumber ] = useState(false);

    useEffect(() => {
        loadNMIframe();
        // eslint-disable-next-line
    },[])

    useLayoutEffect(() => {
        return () => {
            var script=document.getElementById('nmiScript')
            if(script){
                document.getElementById('nmiScript').removeAttribute('data-tokenization-key');
            }
        }
    }, [])

    const loadNMIframe = () => {
        if (window?.CollectJS) {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                fields: {
                    ccnumber: {
                        placeholder: 'Card Number',
                        selector: '#ccnumber',
                    },
                    ccexp: {
                        placeholder: 'Expiry Date',
                        selector: '#ccexp'
                    },
                    cvv: {
                        placeholder: 'CVV',
                        selector: '#cvv'
                    }
                },
                customCss: {
                    border: "none",
                    color: '#000000d9'
                },
                placeholderCss: {
                    color: '#c3c3c3'
                },
                validationCallback: (field, status, message) => {
                    validateFields(status, field)
                },
                callback: (token) => {
                    addCard(token)
                },
            });
        } else {
            setTimeout(() => {loadNMIframe()},10)
        }
    }
    
    const addCard = async(value) => {
        setLoading(true);
        let url = 'guest/payment-instrument';
        const body = {
            "instrumentType": 2,
            "cardDetail": {
                "cardId": value.token,
                "connectorId": connector,
                "last4": value.card.number.slice(-4),
                "expire": value.card.exp,
                "cardType": value.card.type
            }
        }
        var result = await api.mainRestCallService(url, 'POST', body);
        if (result?.status === 1) {
            result.data['connectorId'] = connector
            cardPayment(result.data);
        } else {
            message.error('Failed to add card')
        }
       setLoading(false)
    }  

    const validateFields = (status , field) => {
        if (!status && field === 'ccnumber') {
            setCardNumber(true);
        } else if (status && field === 'ccnumber'){
            setCardNumber(false);
        } 

        if (!status && field === 'ccexp') {
            setExpiry(true)
        } else if (status && field === 'ccexp'){
            setExpiry(false)
        } 

        if (!status && field === 'cvv') {
            setCVV(true)
        } else if (status && field === 'cvv'){
            setCVV(false)
        } 
    }

    const onSubmit = () => {
        if(cardNumber || expiry || CVV){
            setCardNumber(true)
            setExpiry(true)
            setCVV(true)
            return
        }
        setLoading(true)
        window.CollectJS.startPaymentRequest();
    }

    return (
        <Form className="NMI" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }} style={{ width: '100%' }} initialValues={{ country: 1 }} form={form} onFinish={onSubmit}>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CardNumber')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="ccnumber"></div>
                    {cardNumber ? <p className="input-label-error">Please enter valid Card Number</p> : null}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('ExpiryDate')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="ccexp"></div>
                    {expiry ? <p className="input-label-error">Please enter valid Expiration Date</p> : null}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CCV')} </label>
                </Col>
                <Col span={10} className="colDiv">
                    <div id="cvv"></div>
                    {CVV ? <p className="input-label-error">Please enter valid CVV Code</p> : null}
                </Col>
            </Row>

            <Col style={{textAlign: 'center'}}>
                <Button className="enable-btn" type="primary" htmlType="submit"  loading={loading || buttonLoading}>{t("pay")}</Button>
            </Col>

        </Form>
    )
}

export default NmiConnnector;