import { message  } from "antd";
import { loadStripe } from '@stripe/stripe-js';
import NmiConnnector from "./CardConnectors/NMI";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect , useState , useContext} from "react";
import StripeConnector from "./CardConnectors/StripeConnector";
import CardContext from "../../config/CardContext";
import Api from './../../service/api';
import InternalCardService from "./CardConnectors/InternalCardService";

var stripe;
var api = new Api();

const CardAdd = () => {

    const { connector } = useContext(CardContext);
    const [cardSettings , setCardSettings] = useState();
    const [ stripeDetails , setStripeDetails ] = useState();

    useEffect(() => {
        if(connector){
            getCardSettings();

        }
        // eslint-disable-next-line
    },[connector])

    const getCardSettings = async() => {
        setCardSettings();
        const result = await api.mainRestCallService( 'guest/card/settings' , 'GET');
        if(result?.status === 1){
            if(!result.data){
                getSaveCardKey(connector);
            } else {
                getToken();
            }
        }
    }

    const getSaveCardKey = async (connectorId) => {
        const url = "guest/payment/apikey";
        let result = await api.mainRestCallService(url, 'GET');
        if (result?.status === 1) {
            if(connectorId === 3){
                stripe = loadStripe(result.data.stripePublicKey);
                setStripeDetails(result.data.stripePublicKey)
            } else if(connectorId === 4) {
                if(!document.getElementById('nmiScript')){
                    const linkElement = document.createElement("script");
                    linkElement.setAttribute('id' , 'nmiScript')
                    linkElement.src = 'https://secure.safewebservices.com/token/Collect.js';
                    linkElement.setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                    document.head.appendChild(linkElement);
                } else {
                    document.getElementById('nmiScript').setAttribute('data-tokenization-key', result.data.nmiPublicKey);
                }
            }
            setCardSettings(false);
        } else if (result && result?.error?.code !== 401) {
            message.error('Failed to load card add page')
        }
    }

    const getToken = async() => {
        const url =  "guest/card/token";
        let result = await api.mainRestCallService(url, 'GET');
        if (result?.status === 1) {
            if(!document.getElementById('internalScript')){
                const linkElement = document.createElement("script");
                linkElement.setAttribute('id' , 'internalScript');
                linkElement.src = 'https://card-qa.moolapay.net/mcardService.min.js';
                linkElement.setAttribute('data-token', result.data);
                document.head.appendChild(linkElement);
            } else {
                document.getElementById('internalScript').setAttribute('data-token', result.data);
            }
            setCardSettings(true);
        } else if (result && result?.error?.code !== 401) {
            message.error('Failed to load card add page')
        }
    }

    return (
        <div style={{ margin: 'auto' }}>
            {/* <Row className="mainRow" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center" style={{ marginTop: 35 }}> */}
                    {
                        ( connector === 3 && stripeDetails && !cardSettings) ?
                            <Elements stripe={stripe}>
                                <StripeConnector />
                            </Elements>
                        : null
                    }

                    {
                        (connector === 4 && !cardSettings) ?
                            <NmiConnnector />  
                        : null
                    }

                    {
                        (cardSettings && connector) &&
                            <InternalCardService connectorId={connector}/>
                    }

            {/* </Row> */}
        </div>
    )
}

export default CardAdd;
