import React, { useState } from "react";
import './Layout.css'
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { useTranslation } from "react-i18next";

const Layout = (props) => {

  const [language, setLanguage] = useState('en')
  const { i18n } = useTranslation();
  const handleChange = lng => {
    setLanguage(lng)
    i18n.changeLanguage(lng);
    // console.log(lng)
  };


  return (
    <div>
      <header className='header'>
        <nav>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <img src="https://images.ctfassets.net/96ftuyamizgy/2sCIAn2hQy7JvgGKd7V835/ac946a1a9497692050e4c48dc4b5302b/logo.svg" alt="text"/>
            </div>
            <div>
              <Select placeholder="Langauge" value={language} onChange={handleChange}>
                <option key='1' value='en'>English</option>
                <option key='2' value='ch'>简体中文</option>
                <option key='3' value='cht'>繁體中文</option>
              </Select>
            </div>
          </div>
        </nav>
      </header>
      <div className="background">
        <div className="center-box">
          <h1 className='headertitle'>
            {(window.location.hash.indexOf('final') >= 0) ? 'Confirmation' : 'Confirm and Pay'}
          </h1>
          <div className="main-content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout;