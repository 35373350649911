import { useState, useContext } from "react";
import { message, Row, Form, Col, Input, Button } from "antd";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import CardContext from "../../../config/CardContext";
import Api from "../../../service/api";
import { useTranslation } from "react-i18next";

var api = new Api();

const StripeConnector = () => {

    const { t } = useTranslation();
    const stripeMethod = useStripe();
    const [form] = Form.useForm()
    const [code, setcode] = useState('');
    const [codeExp, setcodeExp] = useState('');
    const [codeCVC, setcodeCVC] = useState('');
    const [loading, setLoading] = useState(false);
    let elements = useElements({ hidePostalCode: true });
    const { connector , cardPayment , buttonLoading} = useContext(CardContext)

    const handleCardElementOnChange = (val) => {
        if (!val.error && !val.empty) {
            setcode();
            form.setFieldsValue('cardNumber', val.value)
        } else if (val.error) {
            setcode('invalid_number');
        } else if (val.complete === false) {
            setcode('incomplete_number');
        }
    }

    const handleExpElementOnChange = async (val) => {
        if (!val.error && !val.empty) {
            setcodeExp()
            form.setFieldsValue('cardExpiry', val.value)
        } else if (val.error) {
            setcodeExp('incomplete_expiry');
        } else if (val.complete === false) {
            setcodeExp('invalid_expiry_year_past');
        }
    }

    const handleCVCElementOnChange = async (val) => {
        const error = val.error;
        const empty = val.empty;
        if (!error && !empty) {
            setcodeCVC()
            form.setFieldsValue('cardCVV', val.value)
        } else if (val.error) {
            setcodeCVC('incomplete_cvc');
        } else if (val.complete === false) {
            setcodeCVC('incomplete_number');
        }
    }

    const onSubmit = async (e) => {
        setLoading(true)
        const BillingName = e.name;
        delete e.name;
        const { paymentMethod, error } = await stripeMethod.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            card: elements.getElement(CardExpiryElement),
            card: elements.getElement(CardCvcElement),
            billing_details: {
                name: BillingName,
                address: e
            }
        });

        if (error) {
            setcode(error.code)
            setcodeExp(error.code)
            setcodeCVC(error.code)
            setLoading(false)
        } else {
            paymentMethod['address'] = e;
            addCard(paymentMethod)
        }
    }

    const addCard = async (value) => {
        let url = 'guest/payment-instrument';
        const body = {
            "instrumentType": 2,
            "cardDetail": {
                "cardId": value.id,
                "connectorId": connector
            }
        }
        var result = await api.mainRestCallService(url, 'POST', body);
        if (result?.data) {
            result.data['connectorId'] = connector
            cardPayment(result.data)
            // message.success('Card added successfully')
        } else if (result && result?.error?.code !== 401) {
            message.success('Failed to add card')
        }
        setLoading(false)
    }


    return (
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 10 }} style={{ width: '100%' }} form={form} onFinish={onSubmit}>

            <Form.Item name="name" label={t('AccountName')} colon={false}
                rules={[
                    { required: true, message: 'Please enter the name.' },
                    { pattern: new RegExp("^[a-zA-Z !@#$,./?%^^&*()]+$"), message: 'Please enter valid name' },
                    { min: 3, message: 'Name cannot be less than 3 characters.' }
                ]}>
                <Input key="ach-accountnumber" placeholder="Enter Name" minLength={3} />
            </Form.Item>



            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CardNumber')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <CardNumberElement className={"card add-card"} options={{ invalid: { color: 'rgba(0, 0, 0, 0.85)' }, placeholder: 'Enter card Number' }} required
                        onChange={handleCardElementOnChange.bind(this)} />
                    {code === 'incomplete_number' ? <span className="requiredField">This field is required</span> : ''}
                    {code === 'invalid_number' ? <span className="requiredField">Card number is incorrect</span> : ''}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('ExpiryDate')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <CardExpiryElement className={"card add-card"} options={{ invalid: { color: 'rgba(0, 0, 0, 0.85)' } }} onChange={handleExpElementOnChange.bind(this)} />
                    {(codeExp === 'incomplete_expiry') ? <span className="requiredField">This field is required</span> : ''}
                    {(codeExp === 'invalid_expiry_year_past') ? <span className="requiredField">Expiration date is incorrect</span> : ''}
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={7} className="labelCol">
                    <label><span className="requiredField">*</span> {t('CCV')}</label>
                </Col>
                <Col span={10} className="colDiv">
                    <CardCvcElement className={"card add-card"} options={{ invalid: { color: 'rgba(0, 0, 0, 0.85)' } }} onChange={handleCVCElementOnChange.bind(this)} />
                    {codeCVC === 'incomplete_cvc' ? <span className="requiredField">CVV code is incorrect</span> : ''}
                </Col>
            </Row>

            <Form.Item name="postal_code" label="Zip Code" colon={false}
                rules={[
                    { required: true, message: 'Please enter the zip code.' },
                    { pattern: /^[0-9]{5,5}/, message: 'Please enter valid zip code' },
                    { min: 5, message: 'Zip code cannot be less than 5 digits.' }
                ]}>
                    <Input key="postal_code" placeholder="Enter Zip Code" minLength={5} maxLength={5} />
            </Form.Item>

            <Col style={{textAlign: 'center'}}>
                <Button className="enable-btn" type="primary" htmlType="submit"  loading={loading || buttonLoading}>{t("pay")}</Button>
            </Col>

        </Form>
    )
}

export default StripeConnector;
