import React from "react";
import { Button } from 'antd'

const ErrorPage = () => {

  return (
    <div>
      <header className='header'>
        <nav>
          <div>
            <div>
              <img src="https://images.ctfassets.net/96ftuyamizgy/2sCIAn2hQy7JvgGKd7V835/ac946a1a9497692050e4c48dc4b5302b/logo.svg" alt="text"/>
            </div>
          </div>
        </nav>
      </header>
      <div className="background">
        <div className="center-box" style={{ margin: '100px' }}>
          <div className="main-content" style={{ textAlign: 'center' }}>
            <h1> The page you are looking for no longer exists. Perhaps you can return back.</h1>
            <Button >Go back</Button>
          </div>
        </div>
      </div>
    </div>

  )

}
export default ErrorPage;