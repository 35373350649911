import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";
//import { BrowserRouter as Router, Route } from "react-router-dom";


const Success = (props) => {
    const { state } = useLocation();
    const [status, setStatus] = useState('')
    const [color, setColor] = useState('')
    const checkStatus = () => {
        if (state.status === 99 || state.status === 98 || state.status === 3) {
            setStatus('Failure');
            setColor('red');
        } else if (state.status === 1) {
            setStatus('Pending');
            setColor('blue');
        } else if (state.status === 0) {
            setStatus('Completed');
            setColor('green');
        }
    }
    useEffect(() => {
        checkStatus();
        // eslint-disable-next-line
    }, [])

    return (
        <Layout>
            <div>
                <h1>Transaction Details</h1>
            </div>
            <div>
                Amount : <b> {state.currency} {state.amount} </b> <br />
                Transaction Id :  <b>{state.transaction_id}</b><br />
                {/* Transaction Id :  {state.transaction_id}<br /> */}
                Status : <span style={{ color: color }}><b>{status}</b></span><br />
            </div>
        </Layout>
    )
}

export default Success;