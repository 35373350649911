import axios from 'axios';
import { notification} from 'antd';

var isRefreshing = false;

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/';
  }

  login = async(params) => {
    return await this.mainRestCallService('order/login' , 'POST' , params);
  }

  /**************************************************************************************
   ** mainRestCallService
   ** @param {*} apiPath : api end point
   ** @param {*} method : POST, PUT, DELETE, GET
   ** @param {*} body : request body if not send goes as null
   ** @param {*} asBody : By default true, if false goes as url params.
   ** @returns
   *************************************************************************************/
   mainRestCallService = async (apiPath, method, body, asParams) => {
    var options = {
      method: '',
      body: '',
      headers: ''
    };
    var url = this.api_url + apiPath;
    options.method = method;
    if (asParams === true) {
      url = new URL(`${this.api_url}${apiPath}`)
      Object.keys(body).forEach(key => {
        const value = body[key];
        url.searchParams.append(key, value ? value.toString() : '')
      });
    } else {
      options.body = body ? JSON.stringify(body) : null;
    }
    
    var result;
    if(method === 'POST'){
      result = await this.postMethod(url , body)
    } else if (method === 'GET'){
      result = await this.getMethod(url)
    } else if (method === 'PUT') {
      result = await this.putMethod(url, body)
    }

    if(result === 'session_expired'){
      notification.error({message: 'Session Expired'})
    } else if (result && result.status !== 1) {
      return result.error?.message ?? 'Unsuccessful response';
    } else {
      return result;
    }
  }


  // get Method
  getMethod = async(url) => { return await axios.get(url); }

  // Post Method
  postMethod = (url , body) => { return axios.post(url , body); }

  // Put Method
  putMethod = (url , body) => { return axios.put(url , body); }

}


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.origin + '/',
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(async config => {
  let token;
  
  if (config.url.indexOf('/order/login') !== -1 || config.url.indexOf('/checkout/cancel') !== -1) {
    token = sessionStorage.getItem('order_token');
  } else {
    token = sessionStorage.getItem('access_token');
  }
  
    if (token) { config.headers["Authorization"] = `Token ${token}`; }
    return config;
  },
  error => { return Promise.reject(error);}
);

axios.interceptors.response.use(response => {
    return response.data;
  },
  async function(error) {
    const originalConfig = error.config;
    if (error.response?.status === 401 && isRefreshing === false) {
      isRefreshing = true;
      originalConfig._retry = true;
      try {
        isRefreshing = false;
        return instance(originalConfig);
      } catch (_error) {
        isRefreshing = false;
        window.location = '#/login/timedout';
        return 'session_expired';
      }
    };
    return error.response?.data;
  }
);
